// Core
import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { NavController } from '@ionic/angular';

// Capacitor
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';
import { SplashScreen } from '@capacitor/splash-screen';
import { PushNotifications, ActionPerformed } from '@capacitor/push-notifications';

// Service
import { AuthService } from './shared/service/auth/auth.service';
import { ErrorService } from './shared/service/error/error.service';
import { InfoService } from './shared/service/info/info.service';
import { KeyboardService } from './shared/service/keyboard/keyboard.service';
import { SettingService } from './shared/service/setting/setting.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  constructor(
    private platform: Platform,
    private navController: NavController,
    private authService: AuthService,
    private errorService: ErrorService,
    private infoService: InfoService,
    private keyboardService: KeyboardService,
    private settingService: SettingService,
  ) {
    this.initializeApp();
  }

  // 초기 설정
  public initializeApp() {
    this.platform.ready().then(async () => {
      try {
        // 디바이스 정보
        const deviceInfo = await Device.getInfo();

        // 디바이스 정보 업데이트
        this.infoService.deviceInfo = deviceInfo;

        // IOS 앱 환경에서 구동 설정
        if (deviceInfo.platform === 'ios') {
          // IOS 이벤트 설정
          this.onIosEvent();
        }

        // Android 앱 환경에서 구동 설정
        if (deviceInfo.platform === 'android') {
          // 안드로이드 이벤트 설정
          this.onAndroidEvent();
        }

        // IOS, Android 이벤트 설정
        if (deviceInfo.platform === 'ios' || deviceInfo.platform === 'android') {
          // 키보드 이벤트 활성화
          this.onKeyboardEvent();

          // 앱 버전 체크
          this.onAppVersionCheck();

          // 앱 푸시 알림 확인
          this.onPushNotification();

          // 앱 URL 체크
          this.onAppUrlCheck();
        }

        // 로그인 시간 업데이트
        this.onUpdateSignIn();
      } catch (err) {
        // 에러 생성
        await this.errorService.createError({ message: this.errorService.handler(err) });
      }
    });
  }

  // 앱 버전 체크
  public async onAppVersionCheck(): Promise<void> {
    // 앱 정보
    const appInfo = await App.getInfo();
    const appInfoVersion = Number(appInfo.version.replace('.', '0'));

    // 앱 정보 업데이트
    this.infoService.appInfo = appInfo;
    
    // 앱 버전 체크
    await this.settingService.getSettingVersion();
    const minimumVersion = Number((this.settingService.settingVersion && this.settingService.settingVersion.minimum || '0').replace('.', '0'));

    // 앱 최소버전 보다 낮은 경우
    if (appInfoVersion < minimumVersion) {
      // 버전 업데이트 페이지 이동
      this.navController.navigateRoot(`/set/version`, {
        animationDirection: 'forward',
      });

      // 스플래시 스크린 감추기
      SplashScreen.hide();

      // 종료
      return;
    }
  }

  // 앱 URL 체크
  public async onAppUrlCheck(): Promise<void> {
    App.addListener('appUrlOpen', async (data) => {
      // URL 없을시 종료
      if (!data || !data.url) {
        return;
      }

      try {
        // URL 분석
        const url = new URL(data.url);
        const urlParams = url.searchParams;

        // 모드 확인
        const mode = urlParams.get('mode');
        const oobCode = urlParams.get('oobCode');

        // 비밀번호 재설정으로 이동
        if (mode === 'resetPassword') {
          this.navController.navigateRoot(`/reset/password?oobCode=${oobCode}`, {
            animationDirection: 'forward',
          });

          return;
        }
      } catch (err) {
        // 에러 생성
        await this.errorService.createError({ message: this.errorService.handler(err) });
      }
    });
  }

  // 푸시 알림 확인
  public async onPushNotification(): Promise<void> {
    // 푸시 권한 상태 확인        
    const pushNotificationsPermissionsStatus = await PushNotifications.checkPermissions();

    // 푸시 알림 허용시
    if (pushNotificationsPermissionsStatus.receive === 'granted') {
      // 알림 선택 인입시 해당 알림 제거 이벤트
      PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
        await PushNotifications.removeDeliveredNotifications(notification.notification.data);
      });
    }
  }

  // 키보드 이벤트 설정
  public async onKeyboardEvent(): Promise<void> {
    // 키보드 오픈 이벤트
    Keyboard.addListener('keyboardWillShow', (info: KeyboardInfo) => {
      // 키보드 요소
      const keyboard = document.querySelectorAll('.keyboard');
      const keyboardHeight = document.querySelectorAll('.keyboard-height');
      
      // 키보드 요소 있을시 클래스 추가
      if (keyboard.length) {
        for (let i = 0; i < keyboard.length; i++) {
          keyboard[i].classList.add('keyboard-open');
        }
      }

      // 키보드 요소 있을시 높이 추가
      if (keyboardHeight.length) {
        for (let i = 0; i < keyboardHeight.length; i++) {
          (keyboardHeight[i] as any).style.height = `calc(100% - ${info.keyboardHeight}px)`;
        }
      }

      // 키보드 높이 설정
      this.keyboardService.setKeyboardHeight(info.keyboardHeight);
    });

    // 키보드 닫기 이벤트
    Keyboard.addListener('keyboardWillHide', () => {
      // 키보드 요소
      const keyboard = document.querySelectorAll('.keyboard');

      // 키보드 요소 있을시 클래스 제거
      if (keyboard.length) {
        for (let i = 0; i < keyboard.length; i++) {
          keyboard[i].classList.remove('keyboard-open');
        }
      }
    });
  }

  // IOS 이벤트 설정
  public async onIosEvent(): Promise<void> {
    // 키보드 악세서리 설정
    await Keyboard.setAccessoryBarVisible({
      isVisible: false,
    });

    // 키보드 스크롤 설정
    await Keyboard.setScroll({
      isDisabled: true,
    });
  }

  // Android 이벤트 설정
  public async onAndroidEvent(): Promise<void> {
    App.addListener('backButton', ({ canGoBack }) => {
      // 뒤로가기 있을시
      if (canGoBack) {
        window.history.back();
      }
      // 뒤로가기 없을시
      else {
        App.exitApp();
      }
    });
  }

  // 로그인 시간 업데이트
  public async onUpdateSignIn(): Promise<void> {
    // 로그인 유저 없을시 종료
    if (!this.authService.authUser) {
      setTimeout(() => {
        this.onUpdateSignIn();
      }, 1000);

      return;
    }

    // 로그인 시간 업데이트 요청
    await this.authService.signInAuto(this.authService.authUser.id);
  }

}
