// Core
import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';

// Hammerjs
import * as Hammer from 'hammerjs';

@Injectable({
  providedIn: 'root'
})
export class HammerConfig extends HammerGestureConfig {
  override overrides = <any> {
    'swipe': {
      direction: Hammer.DIRECTION_ALL,
    },
    'tap': {
      taps: 2,
    },
  };
}
