// Core
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Guard
import { AuthGuard } from './shared/guard/auth/auth.guard';

// Routes
const routes: Routes = [
  {
    path: 'sign',
    loadChildren: () => import('./page/sign/sign.module').then(m => m.SignModule),
  },
  {
    path: 'find',
    loadChildren: () => import('./page/find/find.module').then(m => m.FindModule),
  },
  {
    path: 'set',
    loadChildren: () => import('./page/set/set.module').then(m => m.SetModule),
  },
  {
    path: 'reset',
    loadChildren: () => import('./page/reset/reset.module').then(m => m.ResetModule),
  },
  {
    path: 'terms',
    loadChildren: () => import('./page/terms/terms.module').then(m => m.TermsModule),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('./page/home/home.module').then(m => m.HomeModule),
    canActivate: [
      AuthGuard,
    ],
    data: {
      role: [
        'user',
        'admin',
      ],
    },
  },
  {
    path: 'calendar',
    loadChildren: () => import('./page/calendar/calendar.module').then(m => m.CalendarModule),
    canActivate: [
      AuthGuard,
    ],
    data: {
      role: [
        'user',
        'admin',
      ],
    },
  },
  {
    path: 'story',
    loadChildren: () => import('./page/story/story.module').then(m => m.StoryModule),
    canActivate: [
      AuthGuard,
    ],
    data: {
      role: [
        'user',
        'admin',
      ],
    },
  },
  {
    path: 'chart',
    loadChildren: () => import('./page/chart/chart.module').then(m => m.ChartModule),
    canActivate: [
      AuthGuard,
    ],
    data: {
      role: [
        'user',
        'admin',
      ],
    },
  },
  {
    path: 'letter',
    loadChildren: () => import('./page/letter/letter.module').then(m => m.LetterModule),
    canActivate: [
      AuthGuard,
    ],
    data: {
      role: [
        'user',
        'admin',
      ],
    },
  },
  {
    path: 'my',
    loadChildren: () => import('./page/my/my.module').then(m => m.MyModule),
    canActivate: [
      AuthGuard,
    ],
    data: {
      role: [
        'user',
        'admin',
      ],
    },
  },
  {
    path: 'alarm',
    loadChildren: () => import('./page/alarm/alarm.module').then(m => m.AlarmModule),
    canActivate: [
      AuthGuard,
    ],
    data: {
      role: [
        'user',
        'admin',
      ],
    },
  },
  {
    path: 'contact',
    loadChildren: () => import('./page/contact/contact.module').then(m => m.ContactModule),
    canActivate: [
      AuthGuard,
    ],
    data: {
      role: [
        'user',
        'admin',
      ],
    },
  },
  {
    path: 'notice',
    loadChildren: () => import('./page/notice/notice.module').then(m => m.NoticeModule),
    canActivate: [
      AuthGuard,
    ],
    data: {
      role: [
        'user',
        'admin',
      ],
    },
  },
  {
    path: 'change',
    loadChildren: () => import('./page/change/change.module').then(m => m.ChangeModule),
    canActivate: [
      AuthGuard,
    ],
    data: {
      role: [
        'user',
        'admin',
      ],
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule { }
