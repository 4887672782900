// Core
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';

// Service
import { AuthService } from '../../service/auth/auth.service';

// Rxjs
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // 로그인 사용자 권한 확인
    const routeRole: Array<string> = route.data['role'] ? route.data['role'] : [];

    // 가드 설정
    return this.authService.authUser$.pipe(
      take(1),
      map(user => {
        // 로그인 유저 없을 경우
        if (!user) {
          return false;
        }

        // 권한 없을 경우
        if (!routeRole.includes(user.role)) {
          return false;
        }

        // 차단된 경우
        if (user.block) {
          this.router.navigate(['/set/block']);
        }

        // 권한 있을 경우
        return true;
      }),
      tap(isAuthorized => {
        // 권한 없을 경우 로그인 페이지로 이동
        if (!isAuthorized) {
          this.router.navigate(['/sign']);
        }
      }),
    );
  }
  
  
}
