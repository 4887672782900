// Core
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {

  constructor() { }

  // 키보드 높이
  public keyboardHeight = 0;

  // 키보드 높이 설정
  public setKeyboardHeight(height: number): void {
    this.keyboardHeight = height;
  }

}
