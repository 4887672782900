// Core
import { Injectable } from '@angular/core';

// Firebase
import { Firestore, getDoc, doc } from '@angular/fire/firestore';

// Interface
import { ISettingVersion } from '../../interface/setting/setting';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(
    private firestore: Firestore,
  ) { }

  // 버전 설정
  public settingVersion: ISettingVersion | null = null;

  // 버전 설정 조회
  public async getSettingVersion(): Promise<ISettingVersion> {
    // 문서
    const document = doc(this.firestore, 'setting/version');

    // 조회 결과
    const getDocument = await getDoc(document);
    const getDocumentData = getDocument.data() as ISettingVersion;

    // 결과 값
    this.settingVersion = getDocumentData;

    // 데이터 리턴
    return this.settingVersion;
  }

}
