// Core
import { Injectable } from '@angular/core';

// Firebase
import { Firestore, collection, addDoc, serverTimestamp } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private firestore: Firestore,
  ) { }
  
  // 에러 핸들러
  public handler(error: any) {
    // 에러 코드
    const code = error.code || '';

    // 에러 메세지
    let message = '';

    // 코드 있을 시
    if (code) {
      switch (code) {
        case 'auth/user-not-found':
          message = '가입된 이메일이 아니에요. 이메일을 확인해주세요.'
          break;

        case 'auth/wrong-password':
          message = '비밀번호가 올바르지 않아요. 비밀번호를 확인해주세요.'
          break;

        case 'auth/too-many-requests':
          message = '너무 많은 중복 요청이 시도되었어요! 잠시 후 다시 시도해주세요.'
          break;

        case 'auth/operation-not-allowed':
          message = '허용되지 않은 인증방법입니다. 인증 방법을 확인해주세요.'
          break;

        case 'auth/provider-already-linked':
          message = '이미 이메일/비밀번호가 설정되었어요!'
          break;

        case 'auth/expired-action-code':
          message = '비밀번호 재설정 유효기간이 만료되었어요. 재설정 메일을 다시 보내주세요.'
          break;

        case 'auth/invalid-action-code':
          message = '비밀번호 재설정 코드가 유효하지 않아요. 재설정 메일을 다시 보내주세요.'
          break;

        case 'file/only-image-file':
          message = '이미지 파일만 등록해주세요.'
          break;

        default:
          message = '앗! 잠시 후 다시 시도해주세요.'
          break;
      }
    }

    // 코드 없을 시
    else {
      // 응답 에러
      if (error && error.response && error.response.data && error.response.data.error) {
        message = error.response.data.error.message;
      }

      // 응답 에러
      else if (error && error.response && error.response.data) {
        message = error.response.data.message;
      }

      // 요청 에러
      else if (error && error.request) {
        message = error.request.meessage;
      }

      // 기본 에러
      else if (error && error.message) {
        message = error.message;
      }

      // 문자열 에러
      else if(typeof error === 'string') {
        message = error;
      }

      // 에러 객체 없을시
      else {
        message = 'Not Error Message';
      }
    }

    // 메세지 리턴
    return message;
  }

  // 에러 생성 이벤트
  public async createError(data: { message: string }): Promise<void> {
    // 콜렉션 경로
    const ref = collection(this.firestore, 'error');

    // 생성 요청
    await addDoc(ref, {
      ...data,
      createdAt: serverTimestamp(),
    });
  }

}
