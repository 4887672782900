// Core
import { Injectable } from '@angular/core';

// Capacitor
import { AppInfo } from '@capacitor/app';
import { DeviceInfo } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  constructor() { }

  // 앱 정보
  public appInfo: AppInfo | null = null;

  // 디바이스 정보
  public deviceInfo: DeviceInfo | null = null;

}
