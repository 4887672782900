export const environment = {
  production: false,
  firebase: {
    projectId: "innerlyze-develop",
    appId: "1:409149735481:web:1d9869414daeab122343e8",
    storageBucket: "innerlyze-develop.appspot.com",
    locationId: 'asia-northeast3',
    apiKey: "AIzaSyC8WZlXK8r4KpP6RvkmOTAX7oojAeBbw2Y",
    authDomain: "innerlyze-develop.firebaseapp.com",
    messagingSenderId: "409149735481",
  },
};
